import React from 'react';
import { useForm } from 'react-hook-form';
function LanguageForm(props: any) {
    // console.log(props);
    const { id: inputId, name: inputName, code: inputCode, nativeName: inputNativeName, updatedDate: inputUpdatedDate } = props.form;
    const { onSubmit, onSubmitDeleting } = props;
    const { register, handleSubmit, errors } = useForm();
    const currentUpdatedDate = () => {
        if (inputUpdatedDate === undefined) {
            return new Date().toDateString() + ' ' + new Date().toTimeString();
        }
        return new Date(parseInt(inputUpdatedDate)).toDateString() + ' ' + new Date(parseInt(inputUpdatedDate)).toTimeString()
    }
    const onFormSubmit = (data: any) => {        
        // console.log('on form submit, using handle submit from useForm, ', data);
        onSubmit(inputId === undefined || inputId === '' ? data : { id: inputId, ...data});
    }
    const onFormSubmitDeleting = (e: any) => {
        e.preventDefault();
        onSubmitDeleting({ id: inputId });
    }
    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="form-row">
            <div className="form-group col-md-6">
            <label htmlFor="name">Name</label>
            <input type="text" className="form-control" id="name" name="name" defaultValue={inputName} ref={register({ required: true })} />
            <p className="error">{errors.name && "Name is required"}</p>
            </div>
            <div className="form-group col-md-6">
            <label htmlFor="code">Code</label>
            <input type="text" className="form-control" id="code" name="code" defaultValue={inputCode} ref={register({ required: true })}/>
            <p className="error">{errors.code && "Code is required"}</p>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="nativeName">Native Name</label>
            <input type="text" className="form-control" id="nativeName" name="nativeName" placeholder="日本語" defaultValue={inputNativeName} ref={register({ required: true })} />
            <p className="error">{errors.nativeName && "Flag URL is required"}</p>
        </div>
        <div className="form-row">            
            <div className="form-group col-md-12">
            <label htmlFor="updatedDate">Latest Update Date</label>
            <input type="text" disabled className="form-control" id="updatedDate" readOnly defaultValue={currentUpdatedDate()} />
            </div>
        </div>
        
        <button type="submit" className="btn btn-primary">Submit</button>
        <hr></hr>
        { inputId !== undefined ? <button className="btn btn-danger" onClick={onFormSubmitDeleting}>Delete</button> : '' }
        </form>
    )
}

export default LanguageForm;